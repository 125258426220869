import axios from "axios";
import config from "../../config";
export const httpFlightMovementsService = axios.create({
    baseURL: config.AOP_APP_URL + "/flights",
    transformRequest: [
        (data, headers) => {
            if (headers) {
                headers["Content-Type"] = "application/json";
            }
            return JSON.stringify(data);
        },
    ],
});
export const httpPaxService = axios.create({
    baseURL: config.AOP_APP_URL + "/pax",
    transformRequest: [
        (data, headers) => {
            if (headers) {
                headers["Content-Type"] = "application/json";
            }
            return JSON.stringify(data);
        },
    ],
});
export const httpStandsService = axios.create({
    baseURL: config.AOP_APP_URL + "/stands",
    transformRequest: [
        (data, headers) => {
            if (headers) {
                headers["Content-Type"] = "application/json";
            }
            return JSON.stringify(data);
        },
    ],
});
export const httpBaggageService = axios.create({
    baseURL: config.AOP_APP_URL + "/bags",
    transformRequest: [
        (data, headers) => {
            if (headers) {
                headers["Content-Type"] = "application/json";
            }
            return JSON.stringify(data);
        },
    ],
});
export const httpOnTimePerformanceService = axios.create({
    baseURL: config.AOP_APP_URL + "/otp",
    transformRequest: [
        (data, headers) => {
            if (headers) {
                headers["Content-Type"] = "application/json";
            }
            return JSON.stringify(data);
        },
    ],
});
export const httpDeIceService = axios.create({
    baseURL: config.AOP_APP_URL + "/deice",
    transformRequest: [
        (data, headers) => {
            if (headers) {
                headers["Content-Type"] = "application/json";
            }
            return JSON.stringify(data);
        },
    ],
});
export const httpFacilityStatusService = axios.create({
    baseURL: config.AOP_APP_URL + "/erp",
    transformRequest: [
        (data, headers) => {
            if (headers) {
                headers["Content-Type"] = "application/json";
            }
            return JSON.stringify(data);
        },
    ],
});
export const httpOperationalMessagesService = axios.create({
    baseURL: config.AOP_APP_URL + "/operational-messages",
    transformRequest: [
        (data, headers) => {
            if (headers) {
                headers["Content-Type"] = "application/json";
            }
            return JSON.stringify(data);
        },
    ],
});
export const httpAirportTransportationService = axios.create({
    baseURL: config.AOP_APP_URL + "/airport-transportation",
    transformRequest: [
        (data, headers) => {
            if (headers) {
                headers["Content-Type"] = "application/json";
            }
            return JSON.stringify(data);
        },
    ],
});
export const httpAirsidePerformanceService = axios.create({
    baseURL: config.AOP_APP_URL + "/airside-performance",
    transformRequest: [
        (data, headers) => {
            if (headers) {
                headers["Content-Type"] = "application/json";
            }
            return JSON.stringify(data);
        },
    ],
});
export const httpVegvesenDatexService = axios.create({
    baseURL: config.AOP_APP_URL + "/traffic",
    transformRequest: [
        (data) => {
            return data;
        },
    ],
});
export const httpPlanService = axios.create({
    baseURL: config.AOP_APP_URL + "/plan",
    transformRequest: [
        (data, headers) => {
            if (headers) {
                headers["Content-Type"] = "application/json";
            }
            return JSON.stringify(data);
        },
    ],
});
export const httpWeatherService = axios.create({
    baseURL: "/api/weather",
    transformRequest: [
        (data) => {
            return data;
        },
    ],
});
