import { jsx as _jsx } from "react/jsx-runtime";
import { Page } from "@enums";
import { sandboxBaggage, sandboxDeIce } from "@widgets/registry";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import PageLayout from "./PageLayout/PageLayout";
import { getDatePickerSelectOptionsForPage } from "./utils";
const SandboxPage = () => {
    const sandboxWidgets = [...sandboxDeIce, ...sandboxBaggage];
    const { picker: { id }, } = useDatePickerContext();
    return (_jsx(PageLayout, { customDatePickerProps: {
            id,
            selectProps: {
                options: getDatePickerSelectOptionsForPage(Page.Sandbox),
            },
        }, title: "Sandbox", description: "Dette er AOP sandbox. Her finner man komponenter som ikke er i bruk.", widgets: sandboxWidgets }));
};
export default SandboxPage;
