import { jsx as _jsx } from "react/jsx-runtime";
import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useEffect } from "react";
import { getTokenRedirect } from "@auth/authUtils";
import { AuthScope } from "@auth/constants";
import { MenuProvider } from "@contexts/MenuContext";
import { ScreenModeProvider } from "@contexts/ScreenModeContext";
import AuthenticatedApp from "./AuthenticatedApp";
const App = ({ pca }) => {
    const { instance, inProgress, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    useEffect(() => {
        const accessTokenRequest = {
            scopes: [AuthScope.AopAccessApi],
            account: accounts[0],
        };
        const handleGetTokenRedirect = async () => {
            await getTokenRedirect(accessTokenRequest);
        };
        if (!isAuthenticated && inProgress === InteractionStatus.None)
            handleGetTokenRedirect();
    }, [instance, accounts, inProgress, isAuthenticated]);
    return (_jsx(MsalProvider, { instance: pca, children: _jsx(MsalAuthenticationTemplate, { interactionType: InteractionType.Redirect, children: _jsx(MenuProvider, { children: _jsx(ScreenModeProvider, { children: _jsx(AuthenticatedApp, {}) }) }) }) }));
};
export default App;
