export var Page;
(function (Page) {
    Page["Baggage"] = "Baggage";
    Page["Main"] = "Main";
    Page["DeIce"] = "DeIce";
    Page["Delays"] = "Delays";
    Page["FacilityStatus"] = "FacilityStatus";
    Page["Flights"] = "Flights";
    Page["Pax"] = "Pax";
    Page["PunctualityRegularity"] = "PunctualityRegularity";
    Page["Sandbox"] = "Sandbox";
    Page["OperationalMessages"] = "OperationalMessages";
    Page["AirportStatus"] = "AirportStatus";
    Page["AirportTransportation"] = "AirportTransportation";
})(Page || (Page = {}));
