import { AirportRoadDelaysWidget, AirportTrainsStatusesTableWidget } from "@features/AirportTransportation/widgets";
import { BaggageEnroutePerFlightEstimateTableWidget, BaggageEnroutePerFlightEstimateBarChartWidget, BaggageOnBeltPerformanceAverageTableWidget, BaggageOnBeltPerformanceAveragePerFlightCombinedWidget, BaggageEnrouteTableWidget, } from "@features/Baggage/widgets";
import { BaggageDepartedTableWidget } from "@features/Baggage/widgets/BaggageDepartedTableWidget";
import { BaggageFullFlightsWidget } from "@features/Baggage/widgets/BaggageFullFlightsWidget";
import { BaggageNotSentWidget } from "@features/Baggage/widgets/BaggageNotSentWidget";
import { BaggageOnBeltPerformanceAverageLineChartWidget } from "@features/Baggage/widgets/BaggageOnBeltPerformanceAverageLineChartWidget";
import { BaggagePrintedTagsWidget } from "@features/Baggage/widgets/BaggagePrintedTagsWidget";
import { DeIceAverageQueTime, DeIceExpectedNext60Platforms, DeIceMedianTimePerAircraft, DeIceMode, NumberOfPlanesForDeIce, PlatformSpecificDeIceData, } from "@features/DeIce/components";
import { DeIceCapacityBarChartWidget } from "@features/DeIce/widgets";
import { FacilityOpenCasesWidget, FacilityOutOfOrderWidget, FacilityErrorCountWidget, FacilityOpenCasesBaggageArrivalWidget, FacilityOpenCasesBaggageDepartureWidget, FacilityOpenCasesTransferWidget, FacilityOpenCasesCheckInDepartureWidget, FacilityOpenCasesSecurityDepartureWidget, FacilityOpenCasesTurnAroundWidget, FacilityStatusTableWidget, } from "@features/Facility/widgets";
import { FlightMovementsBarChartWidget, FlightMovementsEnrouteWidget, FlightMovementsTodayWidget, FlightMovementsDomesticWidget, FlightMovementsInternationalWidget, } from "@features/FlightMovements/widgets";
import { PunctualityDayLineChartWidget, PunctualityProgressBarWidget, RegularityProgressBarWidget, DelaySummaryWidget, DelayTopFiveDelayCodesPieChartWidget, DelayAirportOperativeDelaysPieChartWidget, DelayCodesBarChartWidget, PunctualityTotalOverviewWidget, RegularityTotalOverviewWidget, PunctualityReportWidget, OnTimePerformanceServiceQualityStepperWidget, OnTimePerformanceServiceQualityRunwayTableWidget, } from "@features/OnTimePerformance/widgets";
import { OntimePerformanceOverviewWidget } from "@features/OnTimePerformance/widgets/OntimePerformanceOverviewWidget";
import { CardTypes, OnTimePerformanceServiceQualityCardsWidget, } from "@features/OnTimePerformance/widgets/OnTimePerformanceServiceQualityCardsWidget";
import { OnTimePerformanceServiceQualityFlowRegulationWidget } from "@features/OnTimePerformance/widgets/OnTimePerformanceServiceQualityFlowRegulationWidget";
import { OnTimePerformanceServiceQualityWrapperWidget } from "@features/OnTimePerformance/widgets/OnTimePerformanceServiceQualityWrapperWidget";
import { OperationalMessagesTableWidget } from "@features/OperationalMessages/widgets";
import { PaxThroughSecurityWidget } from "@features/Pax/widgets";
import { PaxEnrouteTableWidget } from "@features/Pax/widgets/PaxEnrouteTableWidget";
import { PaxPredictionGraphWidget } from "@features/Pax/widgets/PaxPredictionGraphWidget";
import { PlanCreateWidget, PlanOverviewMeteogramWidget, PlanOverviewProcessStatusesWidget, PlanOverviewWeatherWidget, } from "@features/Plan/widgets";
import { PlanOverviewDayPickerWidget } from "@features/Plan/widgets/PlanOverview/PlanOverviewDayPickerWidget";
export const layout = {
    gridRows: 0,
    gridColumns: 0,
    height: "null",
    width: "null",
    grid: "null",
    display: "null",
    alignItems: "null",
    margin: "1rem 0",
};
export const DefaultPermissions = {};
export const PlanCreateWidgets = [
    {
        name: PlanCreateWidget.displayName,
        permissions: DefaultPermissions,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [3, 3, 3],
        columnSpan: [12, 12, 12],
        component: PlanCreateWidget.displayName,
        componentProps: {},
    },
];
export const PlanOverviewWidgets = [
    {
        name: PlanOverviewDayPickerWidget.displayName,
        permissions: DefaultPermissions,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 12],
        component: PlanOverviewDayPickerWidget.displayName,
        componentProps: {},
    },
    {
        name: PlanOverviewProcessStatusesWidget.displayName,
        permissions: DefaultPermissions,
        gridRow: [2, 2, 2],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 12],
        component: PlanOverviewProcessStatusesWidget.displayName,
        componentProps: {},
    },
    {
        name: FlightMovementsBarChartWidget.displayName,
        gridRow: [3, 3, 3],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 12],
        component: FlightMovementsBarChartWidget.displayName,
        componentProps: {
            title: "plan.plannedFlightMovements",
            subTitle: "",
        },
        permissions: DefaultPermissions,
    },
    {
        name: PaxPredictionGraphWidget.displayName,
        gridRow: [4, 4, 4],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 12],
        component: PaxPredictionGraphWidget.displayName,
        componentProps: {
            title: "pax.widgets.paxPredictions.title",
            subTitle: "pax.widgets.paxPredictions.subTitle",
        },
        permissions: DefaultPermissions,
    },
    {
        name: FacilityStatusTableWidget.displayName,
        gridRow: [5, 5, 5],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 12],
        component: FacilityStatusTableWidget.displayName,
        componentProps: {
            Component: FacilityStatusTableWidget,
        },
        permissions: DefaultPermissions,
    },
    {
        name: PlanOverviewWeatherWidget.displayName,
        gridRow: [6, 6, 6],
        gridColumn: [1, 1, 1],
        rowSpan: [2, 2, 1],
        columnSpan: [12, 12, 4],
        component: PlanOverviewWeatherWidget.displayName,
        componentProps: {},
        permissions: DefaultPermissions,
    },
    {
        name: PlanOverviewMeteogramWidget.displayName,
        gridRow: [8, 8, 6],
        gridColumn: [1, 1, 5],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 8],
        component: PlanOverviewMeteogramWidget.displayName,
        componentProps: {},
        permissions: DefaultPermissions,
    },
];
export const deIceWidgets = [
    {
        name: DeIceCapacityBarChartWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [3, 3, 3],
        columnSpan: [12, 12, 12],
        component: DeIceCapacityBarChartWidget.displayName,
        componentProps: {
            title: "charts.deice.bar.capacity.title.text",
            subTitle: "charts.deice.bar.capacity.subTitle",
            minHeight: 681,
        },
        permissions: DefaultPermissions,
    },
    {
        name: PlanOverviewMeteogramWidget.displayName,
        gridRow: [4, 4, 4],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 12],
        component: PlanOverviewMeteogramWidget.displayName,
        componentProps: {},
        permissions: DefaultPermissions,
    },
];
export const currentIataStatusWidgets = [
    {
        name: PunctualityProgressBarWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 6, 6],
        component: PunctualityProgressBarWidget.displayName,
        componentProps: {
            title: "test",
            Component: PunctualityProgressBarWidget,
            tooltipTitles: ["Punktlighet totalt (Avgang)"],
            tooltipTexts: [
                "Oversikt over punktlighetssituasjonen, basert på standardberegninger (avreise med registrert faktisk tidspunkt for avgang fra gate/standplass, der faktisk tid avviker mer enn 15 minutter fra oppsatt tid).",
            ],
            size: "l",
            minHeight: 160,
        },
        permissions: DefaultPermissions,
    },
    {
        name: RegularityProgressBarWidget.displayName,
        gridRow: [2, 1, 1],
        gridColumn: [1, 7, 7],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 6, 6],
        component: RegularityProgressBarWidget.displayName,
        componentProps: {
            Component: RegularityProgressBarWidget,
            size: "l",
            tooltipTitles: ["Regularitet totalt (Avgang)"],
            tooltipTexts: [
                "Oversikt over punktlighetssituasjonen, basert på standardberegning (planlagte flygninger som fjernes på operasjonsdagen regnes som kansellerte, og en del av regularitetsstatistikken)",
            ],
            minHeight: 160,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FlightMovementsTodayWidget.displayName,
        gridRow: [3, 2, 2],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: FlightMovementsTodayWidget.displayName,
        componentProps: {
            Component: FlightMovementsTodayWidget,
            minHeight: 285,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FlightMovementsDomesticWidget.displayName,
        gridRow: [4, 3, 2],
        gridColumn: [1, 1, 5],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: FlightMovementsDomesticWidget.displayName,
        componentProps: {
            Component: FlightMovementsDomesticWidget,
            minHeight: 285,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FlightMovementsInternationalWidget.displayName,
        gridRow: [5, 4, 2],
        gridColumn: [1, 1, 9],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: FlightMovementsInternationalWidget.displayName,
        componentProps: {
            Component: FlightMovementsInternationalWidget,
            minHeight: 285,
        },
        permissions: DefaultPermissions,
    },
    {
        name: DelayTopFiveDelayCodesPieChartWidget.displayName,
        gridRow: [6, 5, 3],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 6, 4],
        component: DelayTopFiveDelayCodesPieChartWidget.displayName,
        componentProps: {
            minHeight: 400,
        },
        permissions: DelayTopFiveDelayCodesPieChartWidget.permissions,
    },
    {
        name: PaxThroughSecurityWidget.displayName,
        gridRow: [8, 6, 3],
        gridColumn: [1, 1, 9],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: PaxThroughSecurityWidget.displayName,
        componentProps: {
            minHeight: 282,
        },
        permissions: DefaultPermissions,
    },
    {
        name: DelayAirportOperativeDelaysPieChartWidget.displayName,
        gridRow: [7, 5, 3],
        gridColumn: [1, 7, 5],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 6, 4],
        component: DelayAirportOperativeDelaysPieChartWidget.displayName,
        componentProps: {
            minHeight: 400,
        },
        permissions: DefaultPermissions,
    },
    {
        name: OperationalMessagesTableWidget.displayName,
        gridRow: [9, 7, 4],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 12],
        component: OperationalMessagesTableWidget.displayName,
        componentProps: {},
        permissions: DefaultPermissions,
    },
];
export const forecastCurrentIataStatusWidgets = [
    {
        name: FlightMovementsTodayWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: FlightMovementsTodayWidget.displayName,
        componentProps: {
            Component: FlightMovementsTodayWidget,
            minHeight: 285,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FlightMovementsDomesticWidget.displayName,
        gridRow: [2, 2, 1],
        gridColumn: [1, 1, 5],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: FlightMovementsDomesticWidget.displayName,
        componentProps: {
            Component: FlightMovementsDomesticWidget,
            minHeight: 285,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FlightMovementsInternationalWidget.displayName,
        gridRow: [3, 3, 1],
        gridColumn: [1, 1, 9],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: FlightMovementsInternationalWidget.displayName,
        componentProps: {
            Component: FlightMovementsInternationalWidget,
            minHeight: 285,
        },
        permissions: DefaultPermissions,
    },
];
export const historicIataStatusWidgets = [
    {
        name: PunctualityProgressBarWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 6, 6],
        component: PunctualityProgressBarWidget.displayName,
        componentProps: {
            Component: PunctualityProgressBarWidget,
            tooltipTitles: ["Punktlighet totalt (Avgang)"],
            tooltipTexts: [
                "Oversikt over punktlighetssituasjonen, basert på standardberegninger (avreise med registrert faktisk tidspunkt for avgang fra gate/standplass, der faktisk tid avviker mer enn 15 minutter fra oppsatt tid).",
            ],
            size: "l",
            minHeight: 160,
        },
        permissions: DefaultPermissions,
    },
    {
        name: RegularityProgressBarWidget.displayName,
        gridRow: [2, 1, 1],
        gridColumn: [1, 7, 7],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 6, 6],
        component: RegularityProgressBarWidget.displayName,
        componentProps: {
            Component: RegularityProgressBarWidget,
            size: "l",
            tooltipTitles: ["Regularitet totalt (Avgang)"],
            tooltipTexts: [
                "Oversikt over punktlighetssituasjonen, basert på standardberegning (planlagte flygninger som fjernes på operasjonsdagen regnes som kansellerte, og en del av regularitetsstatistikken)",
            ],
            minHeight: 160,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FlightMovementsTodayWidget.displayName,
        gridRow: [3, 2, 2],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: FlightMovementsTodayWidget.displayName,
        componentProps: {
            Component: FlightMovementsTodayWidget,
            minHeight: 285,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FlightMovementsDomesticWidget.displayName,
        gridRow: [4, 3, 2],
        gridColumn: [1, 1, 5],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: FlightMovementsDomesticWidget.displayName,
        componentProps: {
            Component: FlightMovementsDomesticWidget,
            minHeight: 285,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FlightMovementsInternationalWidget.displayName,
        gridRow: [5, 4, 2],
        gridColumn: [1, 1, 9],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: FlightMovementsInternationalWidget.displayName,
        componentProps: {
            Component: FlightMovementsInternationalWidget,
            minHeight: 285,
        },
        permissions: DefaultPermissions,
    },
    {
        name: DelayAirportOperativeDelaysPieChartWidget.displayName,
        gridRow: [6, 5, 3],
        gridColumn: [1, 1, 1],
        rowSpan: [2, 2, 2],
        columnSpan: [12, 6, 4],
        component: DelayAirportOperativeDelaysPieChartWidget.displayName,
        componentProps: {
            minHeight: 400,
        },
        permissions: DefaultPermissions,
    },
    {
        name: PaxThroughSecurityWidget.displayName,
        gridRow: [10, 7, 3],
        gridColumn: [1, 1, 5],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: PaxThroughSecurityWidget.displayName,
        componentProps: {
            minHeight: 170,
        },
        permissions: DefaultPermissions,
    },
    {
        name: DelayTopFiveDelayCodesPieChartWidget.displayName,
        gridRow: [8, 5, 3],
        gridColumn: [1, 7, 9],
        rowSpan: [2, 2, 2],
        columnSpan: [12, 6, 4],
        component: DelayTopFiveDelayCodesPieChartWidget.displayName,
        componentProps: {
            minHeight: 400,
        },
        permissions: DelayTopFiveDelayCodesPieChartWidget.permissions,
    },
];
export const todayWidgetFlightInformation = [
    {
        name: FlightMovementsTodayWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: FlightMovementsTodayWidget.displayName,
        componentProps: {
            Component: FlightMovementsTodayWidget,
            minHeight: 285,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FlightMovementsDomesticWidget.displayName,
        gridRow: [2, 2, 1],
        gridColumn: [1, 1, 5],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: FlightMovementsDomesticWidget.displayName,
        componentProps: {
            Component: FlightMovementsDomesticWidget,
            minHeight: 285,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FlightMovementsInternationalWidget.displayName,
        gridRow: [3, 3, 1],
        gridColumn: [1, 1, 9],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: FlightMovementsInternationalWidget.displayName,
        componentProps: {
            Component: FlightMovementsInternationalWidget,
            minHeight: 285,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FlightMovementsBarChartWidget.displayName,
        gridRow: [4, 4, 2],
        gridColumn: [1, 1, 1],
        rowSpan: [2, 2, 2],
        columnSpan: [12, 12, 12],
        component: FlightMovementsBarChartWidget.displayName,
        componentProps: {},
        permissions: DefaultPermissions,
    },
];
export const widgetFlightInformation = [
    {
        name: FlightMovementsTodayWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: FlightMovementsTodayWidget.displayName,
        componentProps: {
            Component: FlightMovementsTodayWidget,
            minHeight: 285,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FlightMovementsDomesticWidget.displayName,
        gridRow: [2, 2, 1],
        gridColumn: [1, 1, 5],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: FlightMovementsDomesticWidget.displayName,
        componentProps: {
            Component: FlightMovementsDomesticWidget,
            minHeight: 285,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FlightMovementsInternationalWidget.displayName,
        gridRow: [3, 3, 1],
        gridColumn: [1, 1, 9],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: FlightMovementsInternationalWidget.displayName,
        componentProps: {
            Component: FlightMovementsInternationalWidget,
            minHeight: 285,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FlightMovementsBarChartWidget.displayName,
        gridRow: [4, 4, 2],
        gridColumn: [1, 1, 1],
        rowSpan: [2, 2, 2],
        columnSpan: [12, 12, 12],
        component: FlightMovementsBarChartWidget.displayName,
        componentProps: {
            minHeight: 863,
        },
        permissions: DefaultPermissions,
    },
];
export const widgetPunctuality = [
    {
        name: PunctualityTotalOverviewWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 6],
        component: PunctualityTotalOverviewWidget.displayName,
        componentProps: {
            minHeight: 334,
        },
        permissions: DefaultPermissions,
    },
    {
        name: RegularityTotalOverviewWidget.displayName,
        gridRow: [2, 2, 1],
        gridColumn: [1, 1, 7],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 6],
        component: RegularityTotalOverviewWidget.displayName,
        componentProps: {
            minHeight: 334,
        },
        permissions: DefaultPermissions,
    },
    {
        name: OntimePerformanceOverviewWidget.displayName,
        gridRow: [3, 3, 2],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 12],
        component: OntimePerformanceOverviewWidget.displayName,
        componentProps: {
            minHeight: 244,
        },
        permissions: DefaultPermissions,
    },
    {
        name: PunctualityDayLineChartWidget.displayName,
        gridRow: [4, 4, 3],
        gridColumn: [1, 1, 1],
        rowSpan: [3, 3, 3],
        columnSpan: [12, 12, 12],
        component: PunctualityDayLineChartWidget.displayName,
        componentProps: {
            minHeight: 528,
        },
        permissions: DefaultPermissions,
    },
];
export const airportTransportationWidgets = [
    {
        name: AirportTrainsStatusesTableWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 12],
        component: AirportTrainsStatusesTableWidget.displayName,
        componentProps: {
            title: "airportTransportation.trainsStatusesTable.title",
            subTitle: "airportTransportation.trainsStatusesTable.subTitle",
        },
        permissions: DefaultPermissions,
    },
    {
        name: AirportRoadDelaysWidget.displayName,
        gridRow: [2, 2, 2],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 12],
        component: AirportRoadDelaysWidget.displayName,
        componentProps: {
            title: "airportTransportation.roadDelays.title",
            subTitle: "airportTransportation.roadDelays.subTitle",
        },
        permissions: DefaultPermissions,
    },
];
export const widgetDelay = [
    {
        name: DelayTopFiveDelayCodesPieChartWidget.displayName,
        gridRow: [3, 3, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 2],
        columnSpan: [12, 6, 3],
        component: DelayTopFiveDelayCodesPieChartWidget.displayName,
        componentProps: {
            minHeight: 400,
        },
        permissions: DelayTopFiveDelayCodesPieChartWidget.permissions,
    },
    {
        name: DelayAirportOperativeDelaysPieChartWidget.displayName,
        gridRow: [4, 3, 1],
        gridColumn: [1, 7, 4],
        rowSpan: [1, 1, 2],
        columnSpan: [12, 6, 3],
        component: DelayAirportOperativeDelaysPieChartWidget.displayName,
        componentProps: {
            minHeight: 400,
        },
        permissions: DefaultPermissions,
    },
    {
        name: DelaySummaryWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 7],
        rowSpan: [2, 2, 2],
        columnSpan: [12, 12, 6],
        component: DelaySummaryWidget.displayName,
        componentProps: {
            title: "Totalt antall forsinkelser",
            minHeight: 215,
        },
        permissions: DefaultPermissions,
    },
    {
        name: DelayCodesBarChartWidget.displayName,
        gridRow: [5, 4, 3],
        gridColumn: [1, 1, 1],
        rowSpan: [4, 4, 4],
        columnSpan: [12, 12, 12],
        component: DelayCodesBarChartWidget.displayName,
        componentProps: {
            title: "charts.onTimePerformance.bar.delayCodes.title",
            subTitle: "charts.onTimePerformance.bar.delayCodes.subTitle",
            minHeight: 796,
        },
        permissions: DelayCodesBarChartWidget.permissions,
    },
];
export const sandboxDeIce = [
    {
        name: DeIceMedianTimePerAircraft.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [3, 3, 3],
        component: DeIceMedianTimePerAircraft.displayName,
        componentProps: {
            id: 0,
        },
        permissions: DefaultPermissions,
    },
    {
        name: DeIceAverageQueTime.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [4, 4, 4],
        rowSpan: [1, 1, 1],
        columnSpan: [3, 3, 3],
        component: DeIceAverageQueTime.displayName,
        componentProps: {
            id: 0,
        },
        permissions: DefaultPermissions,
    },
    {
        name: NumberOfPlanesForDeIce.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [7, 7, 7],
        rowSpan: [1, 1, 1],
        columnSpan: [3, 3, 3],
        component: NumberOfPlanesForDeIce.displayName,
        componentProps: {
            Component: NumberOfPlanesForDeIce,
            title: "Antall via De-ice",
            description: "Gjennomførte avisninger av planlagte avganger",
        },
        permissions: DefaultPermissions,
    },
    {
        name: DeIceExpectedNext60Platforms.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [3, 3, 3],
        component: DeIceExpectedNext60Platforms.displayName,
        componentProps: {},
        permissions: DefaultPermissions,
    },
    {
        name: PlatformSpecificDeIceData.displayName,
        gridRow: [2, 2, 2],
        gridColumn: [1, 1, 1],
        rowSpan: [4, 4, 4],
        columnSpan: [6, 6, 6],
        component: PlatformSpecificDeIceData.displayName,
        componentProps: {
            Component: PlatformSpecificDeIceData,
        },
        permissions: DefaultPermissions,
    },
    {
        name: DeIceMode.displayName,
        gridRow: [2, 2, 2],
        gridColumn: [7, 7, 7],
        rowSpan: [1, 1, 1],
        columnSpan: [3, 3, 3],
        component: DeIceMode.displayName,
        componentProps: {},
        permissions: DefaultPermissions,
    },
];
export const widgetPax = [
    {
        name: PaxThroughSecurityWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 6, 5],
        component: PaxThroughSecurityWidget.displayName,
        componentProps: {
            minHeight: 282,
        },
        permissions: DefaultPermissions,
    },
];
export const todayBaggageArrivalWidgets = [
    {
        name: FlightMovementsEnrouteWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 2],
        component: FlightMovementsEnrouteWidget.displayName,
        componentProps: {
            title: "flightMovements.widgets.enroute.title",
            subTitle: "flightMovements.widgets.enroute.subTitle",
        },
        permissions: DefaultPermissions,
    },
    {
        name: BaggageEnrouteTableWidget.displayName,
        gridRow: [2, 2, 1],
        gridColumn: [1, 1, 3],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 5],
        component: BaggageEnrouteTableWidget.displayName,
        componentProps: {
            Component: BaggageEnrouteTableWidget,
            title: "baggage.widgets.enrouteTable.title",
            subTitle: "baggage.widgets.enrouteTable.description",
        },
        permissions: DefaultPermissions,
    },
    {
        name: PaxEnrouteTableWidget.displayName,
        gridRow: [3, 3, 1],
        gridColumn: [1, 1, 8],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 5],
        component: PaxEnrouteTableWidget.displayName,
        componentProps: {
            Component: PaxEnrouteTableWidget,
            title: "pax.widgets.enrouteTable.title",
            subTitle: "pax.widgets.enrouteTable.description",
        },
        permissions: DefaultPermissions,
    },
    {
        name: BaggageEnroutePerFlightEstimateBarChartWidget.displayName,
        gridRow: [4, 4, 2],
        gridColumn: [1, 1, 1],
        rowSpan: [3, 3, 3],
        columnSpan: [12, 12, 12],
        component: BaggageEnroutePerFlightEstimateBarChartWidget.displayName,
        componentProps: {
            minHeight: 715,
        },
        permissions: BaggageEnroutePerFlightEstimateBarChartWidget.permissions,
    },
    {
        name: BaggageEnroutePerFlightEstimateTableWidget.displayName,
        gridRow: [7, 7, 5],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 12],
        component: BaggageEnroutePerFlightEstimateTableWidget.displayName,
        componentProps: {
            Component: BaggageEnroutePerFlightEstimateTableWidget,
            minHeight: 400,
        },
        permissions: BaggageEnroutePerFlightEstimateTableWidget.permissions,
    },
    {
        name: BaggageOnBeltPerformanceAveragePerFlightCombinedWidget.displayName,
        gridRow: [8, 8, 6],
        gridColumn: [1, 1, 1],
        rowSpan: [3, 3, 3],
        columnSpan: [12, 12, 12],
        component: BaggageOnBeltPerformanceAveragePerFlightCombinedWidget.displayName,
        componentProps: {
            title: "charts.baggage.scatter.onBeltPerformance.perFlight.title",
            subTitle: "charts.baggage.scatter.onBeltPerformance.perFlight.subTitle",
            minHeight: 528,
        },
        permissions: BaggageOnBeltPerformanceAveragePerFlightCombinedWidget.permissions,
    },
    {
        name: BaggageOnBeltPerformanceAverageTableWidget.displayName,
        gridRow: [11, 11, 9],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 12],
        component: BaggageOnBeltPerformanceAverageTableWidget.displayName,
        componentProps: {
            title: "table.baggage.onBeltPerformance.title",
            subTitle: "table.baggage.onBeltPerformance.subTitle",
            minHeight: 294,
        },
        permissions: BaggageOnBeltPerformanceAverageTableWidget.permissions,
    },
];
export const todayBaggageDepartureWidgets = [
    {
        name: BaggagePrintedTagsWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 4, 4],
        component: BaggagePrintedTagsWidget.displayName,
        componentProps: {
            title: "baggage.widgets.printedTags.title",
            subTitle: "baggage.widgets.printedTags.subTitle",
        },
        permissions: DefaultPermissions,
    },
    {
        name: BaggageNotSentWidget.displayName,
        gridRow: [2, 1, 1],
        gridColumn: [1, 5, 5],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 4, 4],
        component: BaggageNotSentWidget.displayName,
        componentProps: {
            title: "baggage.widgets.notSent.title",
            subTitle: "baggage.widgets.notSent.subTitle",
        },
        permissions: DefaultPermissions,
    },
    {
        name: BaggageFullFlightsWidget.displayName,
        gridRow: [3, 1, 1],
        gridColumn: [1, 9, 9],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 4, 4],
        component: BaggageFullFlightsWidget.displayName,
        componentProps: {
            title: "baggage.widgets.fullFlights.title",
            subTitle: "baggage.widgets.fullFlights.subTitle",
        },
        permissions: DefaultPermissions,
    },
    {
        name: BaggageDepartedTableWidget.displayName,
        gridRow: [4, 2, 2],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 12],
        component: BaggageDepartedTableWidget.displayName,
        componentProps: {
            Component: BaggageDepartedTableWidget,
            minHeight: 800,
        },
        permissions: DefaultPermissions,
    },
];
export const historicalBaggageWidgets = [
    {
        name: BaggageOnBeltPerformanceAverageLineChartWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [3, 3, 3],
        columnSpan: [12, 12, 12],
        component: BaggageOnBeltPerformanceAverageLineChartWidget.displayName,
        componentProps: {
            title: "charts.baggage.line.onBeltPerformance.title",
            subTitle: "charts.baggage.line.onBeltPerformance.subTitle",
            minHeight: 715,
        },
        permissions: DefaultPermissions,
    },
];
export const fiveDayReportWidget = [
    {
        name: PunctualityReportWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 12],
        component: PunctualityReportWidget.displayName,
        componentProps: {},
        permissions: DefaultPermissions,
    },
];
export const facilityStatusWidgets = [
    {
        name: FacilityOpenCasesWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 4, 4],
        component: FacilityOpenCasesWidget.displayName,
        componentProps: {
            minHeight: 206,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FacilityOutOfOrderWidget.displayName,
        gridRow: [2, 1, 1],
        gridColumn: [1, 5, 5],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 4, 4],
        component: FacilityOutOfOrderWidget.displayName,
        componentProps: {
            minHeight: 206,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FacilityErrorCountWidget.displayName,
        gridRow: [3, 1, 1],
        gridColumn: [1, 9, 9],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 4, 4],
        component: FacilityErrorCountWidget.displayName,
        componentProps: {
            minHeight: 206,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FacilityOpenCasesBaggageDepartureWidget.displayName,
        gridRow: [4, 2, 2],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 4, 2],
        component: FacilityOpenCasesBaggageDepartureWidget.displayName,
        componentProps: {
            minHeight: 206,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FacilityOpenCasesCheckInDepartureWidget.displayName,
        gridRow: [5, 2, 2],
        gridColumn: [1, 5, 3],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 4, 2],
        component: FacilityOpenCasesCheckInDepartureWidget.displayName,
        componentProps: {
            minHeight: 206,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FacilityOpenCasesSecurityDepartureWidget.displayName,
        gridRow: [6, 2, 2],
        gridColumn: [1, 9, 5],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 4, 2],
        component: FacilityOpenCasesSecurityDepartureWidget.displayName,
        componentProps: {
            minHeight: 206,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FacilityOpenCasesBaggageArrivalWidget.displayName,
        gridRow: [7, 3, 2],
        gridColumn: [1, 1, 7],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 4, 2],
        component: FacilityOpenCasesBaggageArrivalWidget.displayName,
        componentProps: {
            minHeight: 206,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FacilityOpenCasesTransferWidget.displayName,
        gridRow: [8, 3, 2],
        gridColumn: [1, 5, 9],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 4, 2],
        component: FacilityOpenCasesTransferWidget.displayName,
        componentProps: {
            minHeight: 206,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FacilityOpenCasesTurnAroundWidget.displayName,
        gridRow: [9, 3, 2],
        gridColumn: [1, 9, 11],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 4, 2],
        component: FacilityOpenCasesTurnAroundWidget.displayName,
        componentProps: {
            minHeight: 206,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FacilityStatusTableWidget.displayName,
        gridRow: [10, 4, 3],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 12],
        component: FacilityStatusTableWidget.displayName,
        componentProps: {
            Component: FacilityStatusTableWidget,
        },
        permissions: DefaultPermissions,
    },
];
export const processesWidgets = [
    {
        name: OnTimePerformanceServiceQualityStepperWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 12],
        component: OnTimePerformanceServiceQualityStepperWidget.displayName,
        componentProps: {},
        permissions: DefaultPermissions,
    },
    {
        name: OnTimePerformanceServiceQualityRunwayTableWidget.displayName,
        gridRow: [2, 2, 2],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 6],
        component: OnTimePerformanceServiceQualityRunwayTableWidget.displayName,
        componentProps: {},
        permissions: DefaultPermissions,
    },
    {
        name: OnTimePerformanceServiceQualityWrapperWidget.displayName,
        gridRow: [3, 3, 2],
        gridColumn: [1, 1, 7],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 6],
        component: OnTimePerformanceServiceQualityWrapperWidget.displayName,
        componentProps: {},
        permissions: DefaultPermissions,
    },
    {
        name: OnTimePerformanceServiceQualityFlowRegulationWidget.displayName,
        gridRow: [4, 4, 3],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 6, 3],
        component: OnTimePerformanceServiceQualityFlowRegulationWidget.displayName,
        componentProps: {
            type: CardTypes.RECOVERED_DELAY,
        },
        permissions: DefaultPermissions,
    },
    {
        name: OnTimePerformanceServiceQualityCardsWidget.displayName,
        gridRow: [5, 4, 3],
        gridColumn: [1, 7, 4],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 6, 3],
        component: OnTimePerformanceServiceQualityCardsWidget.displayName,
        componentProps: {
            type: CardTypes.RECOVERED_DELAY,
        },
        permissions: DefaultPermissions,
    },
    {
        name: OnTimePerformanceServiceQualityCardsWidget.displayName,
        gridRow: [6, 5, 3],
        gridColumn: [1, 1, 7],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 6, 3],
        component: OnTimePerformanceServiceQualityCardsWidget.displayName,
        componentProps: {
            type: CardTypes.STARTUP_DELAY,
        },
        permissions: DefaultPermissions,
    },
    {
        name: OnTimePerformanceServiceQualityCardsWidget.displayName,
        gridRow: [7, 5, 3],
        gridColumn: [1, 7, 10],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 6, 3],
        component: OnTimePerformanceServiceQualityCardsWidget.displayName,
        componentProps: {
            type: CardTypes.OUTBOUND_TAXI_TIME,
        },
        permissions: DefaultPermissions,
    },
];
export const sandboxBaggage = [];
