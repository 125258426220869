import { jsx as _jsx } from "react/jsx-runtime";
import { isFuture, isPast, isToday } from "date-fns";
import { useTranslation } from "react-i18next";
import { getDateToReadableText } from "@utils/DateUtils";
import { mapAirportIataToName } from "@mappings";
import { currentIataStatusWidgets, forecastCurrentIataStatusWidgets, historicIataStatusWidgets, } from "@widgets/registry";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useIataContext } from "@contexts/IataContext";
import PageLayout from "./PageLayout/PageLayout";
const MainPage = () => {
    const { picker: { id, from }, } = useDatePickerContext();
    const { iata: airport } = useIataContext();
    const { t } = useTranslation();
    const handleWidgetsByDate = () => {
        if (isPast(from) && !isToday(from)) {
            return historicIataStatusWidgets;
        }
        else if (isToday(from)) {
            return currentIataStatusWidgets;
        }
        else if (isFuture(from)) {
            return forecastCurrentIataStatusWidgets;
        }
        else {
            return currentIataStatusWidgets;
        }
    };
    return (_jsx(PageLayout, { customDatePickerProps: {
            id,
        }, title: `${t("pages.main.statusAt")} ${mapAirportIataToName(airport)} ${getDateToReadableText().toLowerCase()}`, widgets: handleWidgetsByDate() }));
};
export default MainPage;
